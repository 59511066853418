@import url('https://fonts.googleapis.com/css2?family=Questrial&display=swap');
body{
  font-weight: 500 !important;
  font-size: 18px !important;
  line-height: 200% !important;
  color: #536270 !important;
  font-family: "Questrial", sans-serif !important;
}
.bg-light {
  color: #536270 !important;
  
}
/* Custom Scrollbar */
*::-webkit-scrollbar {
  width: 6px;
  height: 5px;
}

*::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(41, 36, 36, 0.3);
}

*::-webkit-scrollbar-thumb {
  background-color: #2a2b2b;
}
.logoimg {
  width: auto;
}
.mainnav {
  background: transparent !important;
  position: fixed !important;
  width: 100%;
  z-index: 100;
  padding: 15px 0 !important;
}
.left-top-img {
  position: absolute;
  z-index: 9;
  top: 350px;
  left: -40px;
}
.right-top-img {
  position: absolute;
  z-index: 9;
  top: 200px;
  right: -40px;
}
.right-bottom-img {
  position: absolute;
  z-index: 9;
  bottom: 90px;
  right: -40px;
}
.banner-text-left{
  max-width: 90%;
}
.mainnav.bgcolor {
  background: #effafe !important;
}
.mainnav .butn {
  padding: 8px 15px;
}
.mainnav .butn img {
  height: 25px;
}
.mainnav .navbar-collapse {
  justify-content: flex-end;
}
.mainnav button i {
  color: #fff;
}
.ferzan-token-main-banner{
  min-height: 685px;
}
.ferzan-token-main-banner .banner-text-left{
  max-width: 100%;
  text-align: center;
}
.ferzan-token-main-banner .banner-text-left h1{
  width: 100%;
  display: block;
  text-align: center;
}
.width-60-percent{
  max-width: 60% !important;
  margin-left: auto;
  margin-right: auto;
}
.margin-top-minus-300{
  margin-top: -300px !important;
}
.mainnav .navbar-nav .nav-item {
  margin-left: 15px;
}
.mainnav .navbar-nav .nav-link{
  font-weight: 500;
  font-size: 18px;
  color: #0b2238;
}
.mainnav .navbar-toggler {
  background-color: rgb(66 162 212 / 10%);
  border: 1px solid transparent;
}
.mainnav.navbar-light .navbar-toggler-icon {
  filter: invert(1);
}
.mainnav .primary_btn {
  padding: 8px 15px;
}
.mainnav .primary_btn img {
  height: 25px;
}
.mainnav .primary_btn:hover {
  background: #10d0ff !important;
  color: #000008 !important;
}
.butn {
  color: #00b2f5;
  font-size: 20px;
  font-weight: 600;
  border: 1px solid #00b2f5;
  border-radius: 10px;
  background: transparent;
  transition: all 0.5s ease-in-out;
}
.butn:hover {
  background: #050513;
}
.para {
  font-size: 18px;
}
.hs-card img{
  margin-left: -70px;
}
.home-mission{
  padding: 50px 0 100px;
}
.home-mission-box{
  background: #f5f7f7;
  border-radius: 50px;
  padding: 30px 30px;
}
.mission-content {
  display: flex;
  flex-direction: column;
  height: 100%;
  align-items: flex-start;
  justify-content: center;
}
.home-services{
  padding: 50px 0;
}
.home-services .container p{
  font-weight: 600;
  font-size: 18px;
  line-height: 200%;
  color: #536270;
}
.mission-content h2{
  font-weight: 600;
  font-size: 40px;
  color: #0b2238;
}
.home-fwt-token-bg{
  background: #ffffff url(../assets/images/gradian-bg.png) no-repeat scroll right -50px;
  border-radius: 50px;
  padding: 60px 30px;
  box-shadow: 0 0px 20px rgba($color: #000000, $alpha: 0.1);
}
.wallet-addres-home h4{
  font-weight: 600;
  font-size: 20px;
  color: #edeef8
}
.wallet-addres-home h3{
  font-weight: 600;
  font-size: 30px;
  color: #fff;
}
.hs-card h3{
  font-weight: 600;
  font-size: 30px;
  color: #0b2238;
}
.primary_btn {
  background: #10d0ff;
  color: #03030d;
  font-size: 20px;
  font-weight: 700;
  padding: 13px 35px;
  border-radius: 10px;
  border: 0;
  position: relative;
  z-index: 1;
  transition: all 0.5s ease-in-out;
  border: 1px solid transparent;
  display: flex;
  align-items: center;
}
.primary_btn:hover {
  background: #03030d !important;
  color: #10d0ff !important;
  border: 1px solid #10d0ff;
}
.nav-button{
  border: 1px solid #08a5e7 !important;
  border-radius: 10px !important;
  box-shadow: 3px 3px 25px 0 rgba(0, 173, 238, 0.25) !important;
  background: #f7faff !important;
  font-weight: 600 !important;
  font-size: 20px !important;
  color: #08a5e7 !important;
  display: flex !important;
  align-items: center !important;
  padding: 8px 24px !important;
  margin-left: 20px !important;
}
.nav-button:hover{
  background: linear-gradient(161deg, #367cc6 42.08%, #00adee 68.64%) !important;
  color: #ffffff !important;
}
.nav-icon{
  background: url(../assets/images/nav-button-icon.png) no-repeat scroll 0 0;
  display: inline-block;
  margin-right: 10px;
}
.app-store-icon{
    width: 27px; height: 32px;
    background-position: -10px -10px;
}
.nav-button:hover .app-store-icon{
    background-position: -57px -10px;
}
.get-app-icon{
  width: 18px; height: 26px;
  background-position: -104px -10px;
}
.nav-button:hover .get-app-icon{
  background-position: -10px -62px;
}
.galaxy-store-icon{
  width: 20px; height: 18px;
  background-position: -48px -62px;
}
.nav-button:hover .galaxy-store-icon{
  background-position: -88px -62px;
}
.h2tag {
  font-weight: 600;
  font-size: 40px;
  color: #0b2238;
  margin-bottom: 25px;
}
.h2tag span {
  color: #10d0ff;
}
.h3tag {
  font-size: 35px;
  font-weight: 800;
  margin-bottom: 25px;
}
.h3tag span {
  color: #10d0ff;
}
.h5tag {
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 15px;
}
.mainbanner{
  position: relative;
}
.mainbanner .container {
  position: relative;
  z-index: 99;
}
.mainbanner::before{
  content: "";
  position: absolute;
  display: block;
  width: 672px;
  height: 398px;
  top: -150px;
  left: -50px;
  background: url(../assets/images/banner-top-left-logo.png) no-repeat scroll 0 0;
  z-index: 9;
}
.mainbanner::after{
  content: "";
  position: absolute;
  display: block;
  width: 660px;
  height: 531px;
  top: -150px;
  right: -50px;
  background: url(../assets/images/banner-top-right-logo.png) no-repeat scroll right 0;
  z-index: 9;
}
.banner-right-bg{
  background: url(../assets/images/banner-new-waves.png) no-repeat scroll center center;
  background-size: contain;
  padding:50px 0;
  position: relative;
}

.main_sec {
  padding-top: 150px;
  background:#ffffff;
  background-position-y: -110px;
  background-size: 100%;
  position: relative;
  overflow: hidden;
}
.main_sec::before{
  content: "";
  position: absolute;
  display: block;
  width: 660px;
  height: 660px;
  top: -200px;
  left: -200px;
  border-radius: 100%;
  background: rgba(54, 124, 198, 0.35);
  filter: blur(800px);
  z-index: 1;
}
.main_sec::after{
  content: "";
  position: absolute;
  display: block;
  width: 660px;
  height: 660px;
  top: -150px;
  right: -100px;
  border-radius: 100%;
  background: rgba(0, 173, 238, 0.35);
  filter: blur(500px);
  z-index: 1;
}
.main_sec h1 {
  font-weight: 600;
  font-size: 40px;
  color: #0b2238;
  text-align: left;
}
.banner-text-left{
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  align-items: flex-start;
}
.main_sec .para {
  color: #536270;
}

.pay_sec {
  position: relative;
  padding: 50px 0;
}


.product_sec {
  padding: 50px 0;
}
.product_sec .product_secbg {
  background: #03030f;
  border: 1px solid #090922;
  padding: 50px;
  max-width: 85%;
  margin: 0 auto;
  border-radius: 20px;
}
.product_sec .box {
  text-align: center;
  width: 100%;
  border: 1px solid #4443c4;
  padding: 20px;
  border-radius: 10px;
  transition: 0.5s;
}
.product_sec .box:hover {
  background: #4443c4;
}
.product_sec .box img {
  margin-bottom: 15px;
}
.product_sec .box h5 {
  margin-bottom: 10px;
  color: #10d0ff;
}
.product_sec .box p {
  font-size: 15px;
  margin-bottom: 0;
}
.wallet-addres-home{
  background: url(../assets/images/wallet-address-bg.png) no-repeat scroll 0 0;
  width: 1320px;
  height: 216px;
  max-width: 100%;
  background-size: contain;
  color: #ffffff;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.home-wallet-address{
  padding: 50px 0;
}
.transfer::before {
  background: url("../assets/images/transfer_bg.png") no-repeat center;
  background-size: 100%;
}

.download {
  padding: 50px 0;
}
.download .downloadbg {
  background: url(../assets/images/downloadbg1.png) no-repeat right center
    #03030f;
  padding: 40px;
  border: 1px solid #090922;
  border-radius: 20px;
}
.download .flx {
  display: flex;
  align-items: center;
}
.download .flx button {
  display: flex;
  align-items: center;
  border-radius: 15px;
}
.download .flx button:hover {
  background: rgb(16, 208, 255) !important;
  color: rgb(3, 3, 13) !important;
}
.download .flx .dark,
.download .flx .dark:hover {
  background: transparent !important;
  color: #00b2f5 !important;
  border: 1px solid #00b2f5;
}

.ferzan_apk {
  padding: 50px 0;
}
.ferzan_apk h3 span {
  display: block;
}
.ferzan_apk .flx {
  display: flex;
  flex-wrap: wrap;
}
.ferzan_apk .flx .box {
  width: 50%;
  margin-top: 40px;
}
.ferzan_apk .box h2 {
  font-size: 48px;
  margin-bottom: 5px;
}
.ferzan_apk .box p {
  font-size: 18px;
  color: #10d0ff;
}

.footer {
  background: rgb(54,124,198);
  background: linear-gradient(184deg, rgba(54,124,198,1) 0%, rgba(0,173,238,1) 100%); 
  padding-top: 30px;
}
.footer .flx {
  display: flex;
  align-items: flex-start;
}
.footer .flx img {
  margin-right: 15px;
  // height: 80px;
}
.footer .flx h6 {
  font-size: 26px;
  font-weight: 700;
}
.footer .flx p {
  font-size: 14px;
}
.footer .right {
  max-width: 100% !important;
  margin-left: auto;
}
.footer label {
  font-size: 20px;
  width: 100%;
  margin: 10px 0;
}
.footer .btnflx {
  display: flex;
  align-items: center;
}
.footer input {
  box-shadow: none !important;
  padding: 10px;
  border: 0;
  border-radius: 6px;
}
.footer input::placeholder {
  color: #000;
  font-weight: 400;
  font-size: 16px;
}
.footer .input-group-text {
  background: #10d0ff;
  color: #000008;
  font-weight: 600;
  border: 0;
  border-radius: 6px;
  padding: 10px 25px;
}
.footer .footerblw {
  border-top: 1px solid #62ACFA;
  padding: 15px 0;
  margin-top: 30px;
}
.footer .footerblw p{
  color: #DFE9F7;
}
.footer .footerblw p .brand {
  color: #DFE9F7;
}
.footer .footerblw ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.footer .footerblw a {
  background: #2966A6;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 25px;
  color: #8EDBFF;
  margin-left: 15px;
  text-decoration: none !important;
}
.footer .footerblw a:hover {
  background: rgb(54,124,198);
  background: linear-gradient(184deg, rgba(54,124,198,1) 20%, rgba(28,148,217,1) 80%); 
  color: #ffffff;
}
.footer-top {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.footer-top ul {
  list-style: none;
  display: inline-flex;
  margin: 0;
}
.footer-top ul li a {
  color: #DFE9F7;
  text-decoration: navajowhite;
  padding: 0;
  margin: 0 15px;
  transition: .25s;
}
.footer-top ul li a:hover {
  color: #ffffff;
}
.privacy_page {
  padding: 150px 15px;
}
.privacy_page h2 {
  margin-top: 30px;
  font-size: 22px;
}
.footer_link {
  color: #10d0ff !important;
  margin-bottom: 14px;
  display: block;
  margin-top: 15px;
}

.links {
  color: #10d0ff !important;
}

.f_tech {
  width: 182px;
}
.primary_modal {
  color: #fff;
}
.primary_modal .modal-content {
  background: url("../assets/images/body_bg.png") repeat center;
}
.primary_modal .modal-header .btn-close {
  filter: invert(1);
}
.primary_modal .modal-header {
  border-color: #4443c4;
}
.primary_inp {
  background: #01010d !important;
  border: 1px solid rgb(68 67 196 / 20%) !important;
  border-radius: 10px;
  height: 56px;
  padding: 10px 15px;
  box-shadow: none !important;
  color: #eee !important;
}
.mainnav .navbar-toggler{
  background:transparent !important;
  border: 0 !important;
  outline: none !important;
  box-shadow: none !important;
  padding: 0 !important;
  margin-left: 20px !important;
}
.navbarTogglerIcon{
  background: url(../assets/images/side-menu.png) no-repeat scroll 0 0;
  width: 38px;
  height: 38px; 
  display: block;
}
.menu-right-button{
  display: inline-flex;
  justify-content: flex-end;
}
.da-box{
  background: #00ADEE;
  border-radius: 30px;
  padding: 30px;
}
.image-right{
  text-align: center;
}
.purple-title .h2tag{
  color: #222578 !important;
}
.purple-title .hs-card h3{
  color: #222578 !important;
}
.da-box h2{
  color: #ffffff;
  font-weight: 600;
  font-size: 30px;
  text-align: center;
  color: #fff;
  max-width: 55%;
  margin-left: auto;
  margin-right: auto;
}
.da-box-button{
  display: flex;
  justify-content: center;
  margin-top: 30px;
}
.download-action{
  padding: 30px 0 80px;
}
.content-left{
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: center;
}
.content-left h2{
  font-weight: 600;
  font-size: 40px;
  color: #222578;
}
.address-card h4{
  text-align: left;
}
.address-card h3{
  text-align: left;
  font-weight: 600;
  font-size: 24px;
  color: #fff;
}
@media (max-width: 1399px) {
  .footer .right {
    max-width: 82%;
  }
}
@media (max-width: 1200px) {
  .left-top-img{
    display: none;
  }
  .right-top-img{
    display: none;
  }
  .right-bottom-img{
    display: none;
  }
}
@media (max-width: 1199px) {
  .h2tag {
    font-size: 36px;
  }
  .main_sec h1 {
    font-size: 45px;
  }
  .ferzan_apk .box h2 {
    font-size: 38px;
  }
  .h3tag {
    font-size: 28px;
  }
  .para {
    font-size: 16px;
  }
  .primary_btn {
    font-size: 18px;
  }
  .product_sec .product_secbg {
    max-width: 95%;
  }
  .footer .right {
    max-width: 100%;
    margin-top: 15px;
  }
  .footer label {
    text-align: left;
  }
}
@media (max-width: 991px) {
  .mainnav .navbar-toggler{
    margin-left: 10px !important;
  }
  .nav-button {
    font-size: 14px !important;
    padding: 5px 12px !important;
    margin-left: 10px !important;
  }
  .nav-icon{
    margin-right: 7px;
  }
  .banner-text-left{
    max-width: 100%;
  }
  .wallet-addres-home{
    background-size: cover;
    border-radius: 30px;
    padding: 0 30px;
    word-break: break-all;
    background-position: center center;
    height: auto;
    padding-top: 15px;
    padding-bottom: 15px;
  }
  .address-card{
    padding: 15px 0;
  }
  .wallet-addres-home h3{
    font-size: 24px;
  }
  .address-card h4{
    font-size: 16px;
  }
  .address-card h3{
    font-size: 20px;
  }
  .hs-card img {
    margin: 0 auto;
    display: block;
  }
  .home-fwt-token-bg{
    text-align: center;
  }
  .home-services{
    text-align: center;
  }
  .pay_sec .row{
    flex-direction: column-reverse;
  }
  .mission-content h2{
    text-align: center;
    display: block;
    width: 100%;
  }
  .footer-top{
    flex-direction: column;
  }
  .footer-top ul{
    margin-top: 15px;
  }
  .home-mission-box{
    text-align: center;
  }
  .home-mission-box .row{
    flex-direction: column-reverse;
  }

  .h5tag {
    font-size: 20px;
  }
  .primary_btn {
    padding: 10px 25p;
  }
  .product_sec .product_secbg {
    padding: 20px;
    max-width: 100%;
  }
  .product_sec .box {
    padding: 15px;
  }
  .main_sec .para {
    max-width: 100%;
  }
  .download .downloadbg {
    background-position-x: 10%;
  }
}
@media (max-width: 767px) {
  .nav-button span{
    display: none;
  }
  .nav-icon{
    margin-right: 0;
  }
  .footer-top ul{
    flex-wrap: wrap;
    justify-content: center;
  }
  .banner-text-left h1, .banner-text-left p{
    text-align: center;
  }
  .appbtn{
   margin-bottom: 10px;
   width: 100%;
  }
  section {
    text-align: center;
  }
  .main_sec {
    background-size: cover;
  }
  .main_sec h1 {
    font-size: 32px;
  }
  .h2tag {
    font-size: 30px;
  }
  .mainnav .navbar-nav button {
    margin: 0 0 10px !important;
  }
  .ferzan_apk .box h2 {
    font-size: 30px;
  }
  .ferzan_apk img {
    margin-top: 15px;
  }
  .pay_sec img {
    margin-bottom: 15px;
  }
  .transfer img {
    margin: 25px 0 0;
  }
  .product_sec .box {
    margin-bottom: 15px;
  }
  .footer {
    text-align: center;
  }
  .footer .flx {
    flex-direction: column;
    align-items: center;
  }
  .footer .flx img {
    margin-bottom: 15px;
  }
  .footer .btnflx {
    justify-content: center;
  }
  .footer label {
    text-align: center;
  }
  .footer .footerblw ul {
    justify-content: center;
    margin-top: 10px;
  }
  .footer .footerblw a {
    height: 37px;
    width: 37px;
    font-size: 18px;
    margin: 0 10px;
  }
  .width-60-percent{
    max-width: 100% !important;
  }
  .da-box h2{
    max-width: 100%;
  }
  .mobile-reverse .row{
    flex-direction: column-reverse;
  }
  .banner-text-left h1{
  margin: 0 auto 15px;
  }
}
@media (max-width: 575px) {
  .footer .btnflx {
    flex-direction: column;
  }
  .footer .btnflx p {
    margin-bottom: 10px !important;
  }
  .footer .btnflx a {
    margin-bottom: 10px;
  }
  .download .downloadbg {
    background-size: cover;
  }
  .download .flx {
    flex-direction: column;
  }
  .download .flx button {
    margin: 0 0 10px !important;
    padding: 8px 20px;
    font-size: 16px;
    border-radius: 10px;
  }
  .mainnav .container {
    justify-content: center !important;
  }
  .menu-right-button{
    margin-top: 15px;
  }
  .nav-button{
    margin-right: 10px !important;
  }
}